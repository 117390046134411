import debounce from 'lodash.debounce';
import { cloneElement, useEffect, useRef, useState } from 'react';
import { getClasses } from '@pasqal/core/helpers/styles';
import useClickOutside from '@pasqal/core/hooks/useClickOutside';
import useToggle from '@pasqal/core/hooks/useToggle';

import type { MouseEvent, ReactElement, ReactNode } from 'react';

import '@pasqal/core/ui/components/dropdown/Dropdown/dropdown.css';

// TODO: Create a unique positioning function out of this and the Tooltip one
const positionMenu = (menu: DOMRect, target: DOMRect) => {
  const pos = { x: 0, y: 0 };

  let x;

  if (menu.left < 0) {
    x = -menu.x;
  } else if (menu.right > window.innerWidth) {
    x = target.width - menu.width + (window.innerWidth - target.right);
  }

  pos.x = x;

  return pos;
};

interface IProps {
  id: string;
  className?: string;
  button: ReactElement;
  dropdownContent: ReactNode;
  isOpen?: boolean;
  ariaLabel?: string;
  onToggle?: () => void;
  onClose?: () => void;
  // Opening position
  placement?: 'left' | 'right' | 'top' | 'bottom';
  // Alignment of the options list
  alignToEnd?: boolean;
  isDisabled?: boolean;
  keepOpenOnMenuClick?: boolean;
  type?: 'menu' | 'combobox';
  testId?: string;
}

const styles = {
  base: 'Dropdown',
  position: {
    left: 'Dropdown--left',
    right: 'Dropdown--right',
    top: 'Dropdown--top',
    bottom: 'Dropdown--bottom'
  },
  alignToEnd: 'Dropdown--end',
  isOpen: 'is-open',
  isDisabled: 'is-disabled'
};

export const Dropdown = ({
  id,
  className,
  button,
  dropdownContent,
  isOpen: controlledIsOpen,
  ariaLabel,
  onToggle,
  onClose,
  placement = 'left',
  alignToEnd = false,
  isDisabled = false,
  type = 'menu',
  keepOpenOnMenuClick = false,
  testId
}: IProps) => {
  const [position, setPosition] = useState({
    transform: 'translate(0, 0)'
  });
  const dropdownRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const {
    isOpen: internalIsOpen,
    handleToggle,
    handleClose: internalHandleClose
  } = useToggle(false);
  const isOpen =
    controlledIsOpen !== undefined ? controlledIsOpen : internalIsOpen;

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      internalHandleClose();
    }
  };

  const mainCss = getClasses([
    styles.base,
    styles.position[placement],
    alignToEnd && styles.alignToEnd,
    isOpen && styles.isOpen,
    isDisabled && styles.isDisabled,
    className
  ]);
  const dropdownMenuId = `${id}-menu`;

  const handleToggleButtonClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (onToggle) {
      onToggle();
    } else {
      handleToggle();
    }
  };

  const handleMenuClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    // close the menu after an item has been clicked
    if (!keepOpenOnMenuClick) {
      handleClose();
    }
  };

  useClickOutside({
    el: dropdownRef,
    handler: handleClose,
    isOpen,
    isActive: true
  });

  useEffect(() => {
    // TODO: Refactor this
    const setMenuPosition = () => {
      if (dropdownRef.current !== null && menuRef.current !== null) {
        const target = dropdownRef.current.getBoundingClientRect();
        const menu = menuRef.current.getBoundingClientRect();

        const pos = positionMenu(menu, target);
        setPosition({
          transform: `translate(${pos.x}px, 0px)`
        });
      }
    };
    setMenuPosition();

    const repositionListener = debounce(setMenuPosition, 300);

    window.addEventListener('resize', repositionListener);

    return () => {
      window.removeEventListener('resize', repositionListener);
    };
  }, [isOpen, placement]);

  return (
    <div className={mainCss} ref={dropdownRef} data-testid={testId}>
      <div
        className="Dropdown-toggleButton"
        onClick={handleToggleButtonClick}
        data-testid={testId && `${testId}-toggle-button`}
      >
        {cloneElement(button, {
          'aria-expanded': isOpen,
          'aria-haspopup': type === 'menu' ? 'menu' : 'listbox',
          'aria-controls': dropdownMenuId,
          'aria-label': ariaLabel,
          'aria-disabled': isDisabled
        })}
      </div>
      {isOpen && (
        <div
          id={dropdownMenuId}
          ref={menuRef}
          className="Dropdown-menu"
          style={position}
          data-testid={testId && `${testId}-dropdown-menu`}
          onClick={handleMenuClick}
        >
          {dropdownContent}
        </div>
      )}
    </div>
  );
};
